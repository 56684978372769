//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
@use "../../../@core/scss/base/bootstrap-extended/variables" as vars;

$body-bg: #F7F7F7;
$body-color: #596778;

//$purple: #7367f0 !default; //$primary
$green: #4AB762; //$success
//$blue: #00cfe8 !default; //$info
$orange: #FF9718; //$warning
$black: #101728;
$red: #FF6957; //$danger

$c-blue: #0B409B;
$c-white-blue: #E9F4FF;

$primary: $c-blue;
//$secondary: #82868b !default;
//$success: $green !default;
//$danger: $red !default;
//$info: $blue !default;
//$warning: $orange !default;
//$light: $gray-50 !default;
//$dark: #4b4b4b !default;

$h1-font-size: vars.$font-size-base * 1.53;
$h2-font-size: vars.$font-size-base * 1.2;
$h3-font-size: vars.$font-size-base * 1.066;
$h4-font-size: vars.$font-size-base * 1.07;
$h5-font-size: vars.$font-size-base * 1;

$headings-color: $black;

$base-font-size: 14px;

$btn-border-radius: 5px;

$font-family-monospace: 'Inter', Helvetica, Arial, serif;
$font-family-sans-serif: 'Inter', Helvetica, Arial, serif;

$headings-font-family: $font-family-monospace;

$form-label-font-weight: 500;
$form-label-font-size: $base-font-size;
$form-label-color: $headings-color;

$form-check-input-border: 1px solid $primary;
$input-color: $black;
$input-placeholder-color: #9FA6B2;

//$form-check-label-color: vars.$secondary;

$nav-pills-border-radius: 4px;

$font-weight-bold: 600;
$font-weight-bolder: 700;

$card-color: $black;
$card-cap-color: vars.$gray-700;
//$card-box-shadow: v


