//@use "sass:meta";
@use "../variables/variables" as vars;
@use "../../../@core/scss/base/bootstrap-extended/variables" as defaultVars;

.Toastify__toast-icon {
	margin-right: 1rem;
}

.Toastify__toast {
	padding: 1rem;
}

.Toastify__toast-body {
	padding: 0;
}

.Toastify__close-button {
	margin: 0 !important;
}

@mixin add-css-variables($module) {
	:root {
		@each $name, $value in $module {
			--#{$name}: #{$value};
		}
	}
}

$toastVars: (
	'toastify-color-dark': defaultVars.$dark,
	'toastify-color-info': defaultVars.$info,
	'toastify-color-success': vars.$primary,
	'toastify-color-warning': defaultVars.$warning,
	'toastify-color-error': vars.$red,
	//'toastify-color-transparent': rgba(255, 255, 255, 0.7),
	//'toastify-icon-color-info': toastify-color-info,
	//'toastify-icon-color-success': $toastify-color-success,
	//'toastify-icon-color-warning': $toastify-color-warning,
	//'toastify-icon-color-error': $toastify-color-error,
	'toastify-toast-width': 25em,
	///--toastify-toast-background: #fff;
	///--toastify-toast-min-height: 64px;
	///--toastify-toast-max-height: 800px;
	'toastify-font-family': vars.$font-family-monospace,
	///--toastify-z-index: 9999,
	///--toastify-text-color-light: #757575;
	///--toastify-text-color-dark: #fff;
);

@include add-css-variables($toastVars);

//@each $key, $value in $toastVars {
//	--#{$key}: #{$value};
//}

