@import "style";
@import "toast/toast";

.page-item.active .page-link {
	border-radius: 0.25rem !important;
}

html .navbar-hidden .app-content {
	@media (max-width: 1200px) {
		padding: 1rem !important;
	}
}
.fs-media-370 {
	@media (max-width: 370px) {
		font-size: 12px !important;
	}
}
[dir=ltr] body th, body td {
	padding: 1em !important;
	white-space: nowrap;
	@media (max-width: 900px) {
		padding: 0.5em !important;
	}
	.sort-arrow {
		padding-right: 1em;
		@media (max-width: 900px) {
			padding-right: 0.5em;
		}
	}
}